// AuthProvider.js
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import { jwtDecode }from "jwt-decode"; // Correct import
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const navigate = useNavigate();

  const setToken = useCallback((token) => {
    setToken_(token);
  }, []);

  const logout = useCallback(() => {
    setToken_(null);
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);
      setDecodedToken(jwtDecode(token));
    } else {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
      setDecodedToken(null);
    }
  }, [token]);

  const contextValue = useMemo(
    () => ({ token, setToken, logout, decodedToken }),
    [token, setToken, logout, decodedToken]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider };
export default AuthProvider;
