import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  useMediaQuery,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import CustomTable from "../styles/CustomTable";
import axios from "axios";
import { useParams } from "react-router-dom";
import theme from "../styles/theme";

const backendUrl = process.env.REACT_APP_API_URL;

function Suunnitelmat() {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [elements, setElements] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    plan_type_id: "",
    element_id: "",
  });
  const [editRowData, setEditRowData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { asoyId } = useParams();
  const [editFieldErrors, setEditFieldErrors] = useState({});

  // Fetch plans for the asoy
  const fetchPlans = useCallback(async () => {
    try {
      const response = await axios.get(`${backendUrl}/asoy/${asoyId}/plans/`);

      // Validate and map data to ensure proper structure
      const formattedPlans = response.data.map((plan) => ({
        id: plan.id,
        name: plan.name || "Unknown",
        description: plan.description || "No description",
        price: plan.price ? `${plan.price}€` : "0€", // Include euro symbol
        plan_type: plan.plan_type ? plan.plan_type.name : "Unknown", // If `plan_type` is an object, get its `name`
        plan_type_id: plan.plan_type ? plan.plan_type.id : "", // For editing
        element: plan.element ? plan.element.name : "", // If `element` is an object, get its `name`
        element_id: plan.element ? plan.element.id : "", // For editing
      }));

      setRows(formattedPlans);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  }, [asoyId]);

  // Fetch plan types for selection
  const fetchPlanTypes = useCallback(async () => {
    try {
      const response = await axios.get(`${backendUrl}/plan-types/`);
      setPlanTypes(response.data);
    } catch (error) {
      console.error("Error fetching plan types:", error);
    }
  }, []);

  // Fetch elements associated with the asoy through structural_elements
  const fetchElements = useCallback(async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/asoy/${asoyId}/elements/`
      );
      setElements(response.data);
    } catch (error) {
      console.error("Error fetching elements:", error);
    }
  }, [asoyId]);

  useEffect(() => {
    fetchPlans();
    fetchPlanTypes();
    fetchElements();
  }, [fetchPlans, fetchPlanTypes, fetchElements]);

  // Handle Add Plan dialog open/close
  const handleAddRow = async () => {
    const errors = {};
    // Plan type is required
    if (!formData.plan_type_id) errors.plan_type_id = "Lisää suunnitelmatyyppi";
    // Validate price
    if (formData.price && isNaN(Number(formData.price))) {
      errors.price = "Hinta saa sisältää vain numeroita";
    }
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    try {
      const payload = { ...formData, asoy_id: asoyId };
      // Handle element_id when it's an empty string
      payload.element_id =
        payload.element_id === "" ? null : payload.element_id;
      await axios.post(`${backendUrl}/plans/`, payload);
      fetchPlans(); // Refresh data
      handleClose();
    } catch (error) {
      console.error("Error adding plan:", error);
    }
  };

  // Handle Edit Plan dialog open/close
  const handleEditOpen = (row) => {
    setEditRowData({
      ...row,
      price: row.price ? row.price.replace("€", "") : "", // Remove euro symbol for editing
      plan_type_id: row.plan_type_id || "", // Set the selected plan_type_id
      element_id: row.element_id || "", // Ensure element_id is an empty string if null
    });
    setEditOpen(true);
  };

  // Handle saving edits
  const handleEditSave = async () => {
    const errors = {};
    // Plan type is required
    if (!editRowData.plan_type_id)
      errors.plan_type_id = "Lisää suunnitelmatyyppi";
    // Validate price
    if (editRowData.price && isNaN(Number(editRowData.price))) {
      errors.price = "Hinta saa sisältää vain numeroita";
    }
    if (Object.keys(errors).length > 0) {
      setEditFieldErrors(errors);
      return;
    }

    try {
      const updatePayload = {
        name: editRowData.name,
        description: editRowData.description,
        price: editRowData.price,
        plan_type_id: editRowData.plan_type_id,
        element_id:
          editRowData.element_id === "" ? null : editRowData.element_id,
      };
      await axios.patch(
        `${backendUrl}/plans/${editRowData.id}/`,
        updatePayload
      );
      setEditFieldErrors({});
      setEditOpen(false);
      fetchPlans(); // Refresh data
    } catch (error) {
      console.error("Error updating plan:", error);
    }
  };

  // Delete a plan
  // Delete a plan
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${backendUrl}/plans/${id}/`);
      // Remove the deleted plan from the rows state
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setEditOpen(false); // Close the edit dialog after deletion
    } catch (error) {
      console.error("Error deleting plan:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setFormData({
      name: "",
      description: "",
      price: "",
      plan_type_id: "",
      element_id: "",
    });
    setFieldErrors({});
  };

  const columns = [
    { id: "name", label: "Nimi" },
    { id: "description", label: "Kuvaus" },
    { id: "price", label: "Hinta" },
    { id: "plan_type", label: "Tyyppi" },
    { id: "element", label: "Elementti" },
  ];

  return (
    <Box sx={{ display: "flex", marginLeft: isSmallScreen ? "-60px" : "0px" }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <CustomTable
          columns={columns}
          rows={rows}
          onRowClick={handleEditOpen}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            mt: 2,
          }}
        >
          <Button variant="contained" onClick={() => setOpen(true)}>
            Lisää suunnitelma
          </Button>
        </Box>
        {/* Add Plan Dialog */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Lisää suunnitelma</DialogTitle>
          <DialogContent>
            <TextField
              label="Nimi"
              fullWidth
              margin="dense"
              variant="standard"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <TextField
              label="Kuvaus"
              fullWidth
              margin="dense"
              variant="standard"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
            <TextField
              label="Hinta"
              fullWidth
              margin="dense"
              variant="standard"
              value={formData.price}
              onChange={(e) =>
                setFormData({ ...formData, price: e.target.value })
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
              }}
              error={Boolean(fieldErrors.price)}
              helperText={fieldErrors.price}
            />

            <FormControl
              fullWidth
              margin="dense"
              variant="standard"
              error={Boolean(fieldErrors.plan_type_id)}
            >
              <InputLabel required>Tyyppi</InputLabel>
              <Select
                value={formData.plan_type_id}
                onChange={(e) =>
                  setFormData({ ...formData, plan_type_id: e.target.value })
                }
                displayEmpty
                fullWidth
                variant="standard"
              >
                {planTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              {fieldErrors.plan_type_id && (
                <FormHelperText>{fieldErrors.plan_type_id}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel>Elementti</InputLabel>
              <Select
                value={formData.element_id}
                onChange={(e) =>
                  setFormData({ ...formData, element_id: e.target.value })
                }
                fullWidth
                variant="standard"
                label="Elementti"
                renderValue={(selected) => {
                  if (selected === "") {
                    return <em>Ei valintaa</em>;
                  }
                  const selectedElement = elements.find(
                    (element) => element.id === selected
                  );
                  return selectedElement ? selectedElement.name : "";
                }}
              >
                <MenuItem value="">
                  <em>Ei valintaa</em>
                </MenuItem>
                {elements.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Peruuta</Button>
            <Button onClick={handleAddRow}>Lisää</Button>
          </DialogActions>
        </Dialog>
        {/* Edit Plan Dialog */}
        <Dialog open={editOpen} onClose={handleClose}>
          <DialogTitle>Muokkaa suunnitelmaa</DialogTitle>
          <DialogContent>
            <TextField
              label="Nimi"
              fullWidth
              margin="dense"
              variant="standard"
              value={editRowData.name || ""}
              onChange={(e) =>
                setEditRowData({ ...editRowData, name: e.target.value })
              }
            />
            <TextField
              label="Kuvaus"
              fullWidth
              margin="dense"
              variant="standard"
              value={editRowData.description || ""}
              onChange={(e) =>
                setEditRowData({ ...editRowData, description: e.target.value })
              }
            />
            <TextField
              label="Hinta"
              fullWidth
              margin="dense"
              variant="standard"
              value={editRowData.price || ""}
              onChange={(e) =>
                setEditRowData({ ...editRowData, price: e.target.value })
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
              }}
              error={Boolean(editFieldErrors.price)}
              helperText={editFieldErrors.price}
            />

            <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel required>Tyyppi</InputLabel>
              <Select
                value={editRowData.plan_type_id || ""}
                onChange={(e) =>
                  setEditRowData({
                    ...editRowData,
                    plan_type_id: e.target.value,
                  })
                }
                displayEmpty
                fullWidth
                variant="standard"
              >
                {planTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel>Elementti</InputLabel>
              <Select
                value={editRowData.element_id || ""}
                onChange={(e) =>
                  setEditRowData({
                    ...editRowData,
                    element_id: e.target.value,
                  })
                }
                fullWidth
                variant="standard"
                label="Elementti"
                renderValue={(selected) => {
                  if (selected === "") {
                    return <em>Ei valintaa</em>;
                  }
                  const selectedElement = elements.find(
                    (element) => element.id === selected
                  );
                  return selectedElement ? selectedElement.name : "";
                }}
              >
                <MenuItem value="">
                  <em>Ei valintaa</em>
                </MenuItem>
                {elements.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Peruuta</Button>
            <Button onClick={handleEditSave}>Tallenna</Button>
            <Button onClick={() => handleDelete(editRowData.id)} color="error">
              Poista
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default Suunnitelmat;
