// App.js
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import { RouterProvider } from "react-router-dom";
import router from "./auth/Routes";
import CacheProvider from "./CacheProvider";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CacheProvider>
        <RouterProvider router={router} />
      </CacheProvider>
    </ThemeProvider>
  );
};

export default App;
