import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CustomTable from "../styles/CustomTable";
import { useCache } from "../CacheProvider";
import { useMediaQuery } from "@mui/material";
import theme from "../styles/theme";

const backendUrl = process.env.REACT_APP_API_URL;

function Maintenances() {
  const [rows, setRows] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({
    elementId: "",
    element: "",
    maintInterval: "",
    description: "",
  });
  const [allSelected, setAllSelected] = useState(false);
  let { asoyId } = useParams();
  const { get, set, remove } = useCache();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchInspectionData = useCallback(async () => {
    const cachedData = get(`asoy_${asoyId}_maintenance_due`);
    const cachedSelection = get(`asoy_${asoyId}_selected`);

    if (cachedData) {
      const adjustedData = adjustNextInspectionYear(cachedData);
      setRows(adjustedData);
      if (cachedSelection) {
        // Restore the cached selection state
        const updatedRows = adjustedData.map((item) => ({
          ...item,
          tehty: cachedSelection[item.elementId] ?? item.tehty,
        }));
        setRows(updatedRows);
        updateSelectAllStatus(updatedRows); // Initialize 'Select All' status
      }
      return;
    }

    try {
      const response = await axios.get(
        `${backendUrl}/asoy/${asoyId}/maintenance-due/`
      );
      const adjustedData = adjustNextInspectionYear(response.data);
      setRows(adjustedData);
      set(`asoy_${asoyId}_maintenance_due`, adjustedData);
      updateSelectAllStatus(adjustedData); // Initialize 'Select All' status
    } catch (error) {
      console.error("Error fetching maintenance due data:", error);
    }
  }, [asoyId, get, set]);

  const adjustNextInspectionYear = (data) => {
    const currentYear = new Date().getFullYear();
    return data.map((item) => {
      if (item.maintInterval === 1) {
        return {
          ...item,
          nextInspectionYear: currentYear,
        };
      }
      return item;
    });
  };
  const refreshData = async () => {
    remove(`asoy_${asoyId}_maintenance_due`);  // Clear cache
    await fetchInspectionData();               // Re-fetch data from backend
  };
  

  useEffect(() => {
    fetchInspectionData();
  }, [fetchInspectionData]);

  const handleEditOpen = (row) => {
    setCurrentEdit(row);  // Set the row data to be edited
    setEditOpen(true);    // Open the edit dialog
  };
  
  const handleEditClose = () => {
    setEditOpen(false);   // Close the edit dialog without saving
  };
  
  const handleEditSave = async () => {
    const interval = parseInt(currentEdit.maintInterval, 10);
    if (isNaN(interval) || interval < 0) {
      console.error("Invalid maintenance interval:", interval);
      return;
    }
  
    try {
      const payload = {
        maint_interval: interval,
        description: currentEdit.description,
      };
      console.log("Payload being sent:", payload);
  
      const response = await axios.put(
        `${backendUrl}/maintenances/${currentEdit.elementId}/update-interval/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        const updatedRows = rows.map((row) => {
          if (row.elementId === currentEdit.elementId) {
            return {
              ...row,
              maintInterval: interval,
              description: currentEdit.description,
            };
          }
          return row;
        });
        setRows(adjustNextInspectionYear(updatedRows));
        refreshData();
        setEditOpen(false);
      }
    } catch (error) {
      console.error("Error updating maintenance interval:", error);
    }
  };
  

  const handleCheckboxChange = async (event, elementId) => {
    event.stopPropagation();

    try {
      const response = await axios.patch(
        `${backendUrl}/maintenances/${elementId}/toggle-status/`
      );

      const updatedRows = rows.map((row) => {
        if (row.elementId === elementId) {
          return { ...row, tehty: response.data.element.maint_done };
        }
        return row;
      });

      setRows(updatedRows);
      updateSelectAllStatus(updatedRows);

      // Cache the updated selection state
      cacheSelectionState(updatedRows);
    } catch (error) {
      console.error("Failed to update maintenance status:", error);
    }
  };

  const handleSelectAll = async () => {
    const newStatus = !allSelected;
    const updatedRows = rows.map((row) => ({
      ...row,
      tehty: newStatus,
    }));

    try {
      await Promise.all(
        rows.map((row) =>
          axios.patch(
            `${backendUrl}/maintenances/${row.elementId}/toggle-status/`,
            { tehty: newStatus }
          )
        )
      );

      setAllSelected(newStatus);
      setRows(updatedRows);
      cacheSelectionState(updatedRows); // Cache the selection state
    } catch (error) {
      console.error("Failed to update maintenance status on backend:", error);
    }
  };

  const updateSelectAllStatus = (data) => {
    setAllSelected(data.every((row) => row.tehty));
  };

  const cacheSelectionState = (data) => {
    // Store selection state as { elementId: tehty }
    const selectionState = data.reduce((acc, row) => {
      acc[row.elementId] = row.tehty;
      return acc;
    }, {});
    set(`asoy_${asoyId}_selected`, selectionState);
  };

  const columns = [
    { id: "element", label: "Elementit" },
    { id: "maintInterval", label: "Huoltoväli (Vuosia)", align: "right" },
    { id: "nextInspectionYear", label: "Seuraava huolto", align: "right" },
    { id: "description", label: "Selitys", align: "left" },
    {
      id: "tehty",
      label: (
        <Checkbox
          checked={allSelected}
          onChange={handleSelectAll}
          sx={{
            color: "#3B3F63",
            "&.Mui-checked": { color: "#3B3F63" },
          }}
        />
      ),
      align: "right",
      render: (row) => (
        <Checkbox
          checked={row.tehty}
          onClick={(event) => handleCheckboxChange(event, row.elementId)}
          sx={{
            color: "#3B3F63",
            "&.Mui-checked": { color: "#3B3F63" },
          }}
        />
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", marginLeft: isSmallScreen ? "-60px" : "0px" }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <CustomTable
          columns={columns}
          rows={rows}
          onRowClick={handleEditOpen}
        />

        <Dialog open={editOpen} onClose={handleEditClose}>
          <DialogTitle>Muokkaa huoltoväliä</DialogTitle>
          <DialogContent>
            <Box mb={2} mt={2}>
              <InputLabel id="element">Elementti</InputLabel>{" "}
              <TextField
                autoFocus
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.element}
                InputProps={{ readOnly: true }}
              />
            </Box>
            <Box mb={2}>
              <InputLabel required id="year-select-label">
                Huoltoväli (vuosia)
              </InputLabel>{" "}
              <Select
                value={currentEdit.maintInterval}
                variant="standard"
                onChange={(e) =>
                  setCurrentEdit({
                    ...currentEdit,
                    maintInterval: e.target.value,
                  })
                }
                displayEmpty
                fullWidth
              >
                {Array.from(new Array(100), (val, index) => 1 + index).map(
                  (year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
            <Box mb={2}>
              <InputLabel id="description">Selitys</InputLabel>{" "}
              <TextField
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.description}
                onChange={(e) =>
                  setCurrentEdit({
                    ...currentEdit,
                    description: e.target.value,
                  })
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Peru</Button>
            <Button onClick={handleEditSave}>Tallenna</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default Maintenances;
