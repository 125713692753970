// routes.js
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import AuthProvider, { useAuth } from "./Authentication"; // Import AuthProvider and useAuth
import Home from "../pages/Home";
import Reports from "../pages/Reports";
import Login from "../pages/Login";
import AsoyDetails from "../pages/AsoyDetails";
import UserManagement from "../pages/UserManagement";
import BuildingManager from "../pages/BuildingManager";
import TeamManagement from "../pages/TeamManagement";
import AsoyManagement from "../pages/AsoyManagement";
import AsoyProvider from "../AsoyProvider";

const ProtectedLayout = () => {
  const { token } = useAuth();
  const isAuthenticated = !!token;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
};

const AdminLayout = () => {
  const { decodedToken } = useAuth();

  if (decodedToken && decodedToken.role !== 1) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

// Define and export the router
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      }
    >
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="raportit" element={<Reports />} />
        <Route path="raportit/:asoyId" element={<AsoyDetails />} />
        <Route element={<AdminLayout />}>
          <Route path="user-management" element={<UserManagement />} />
          <Route
            path="building-manager-management"
            element={
              <AsoyProvider>
                <BuildingManager />
              </AsoyProvider>
            }
          />
          <Route
            path="team-management"
            element={
              <AsoyProvider>
                <TeamManagement />
              </AsoyProvider>
            }
          />
          <Route
            path="asoy-management"
            element={
              <AsoyProvider>
                <AsoyManagement />
              </AsoyProvider>
            }
          />
        </Route>
      </Route>
    </Route>
  )
);

export default router;
